import React from "react";

const Footer = () => (
	<footer className="bg-light p-3 text-center">
		<div className="logo" />
		<p>
			© 2020 <a href="mailto:james.mattner@gmail.com">James Mattner</a>
		</p>
	</footer>
);

export default Footer;
