import React, { Component } from "react";

import { Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import contentData from "../utils/contentData";

class Content extends Component {
	render() {
		return (
			<div className="next-steps my-5">
				<h2 className="my-5 text-center">why the fuck am i here ?</h2>
				<Row className="d-flex justify-content-between">
					{contentData.map((col, i) => (
						<Col key={i} md={5} className="mb-4">
							<h6 className="mb-3">
								<a href={col.link}>
									<FontAwesomeIcon icon="link" className="mr-2" />
									{col.title}
								</a>
							</h6>
							<p>{col.description}</p>
						</Col>
					))}
					<iframe title="steam" src="https://store.steampowered.com/widget/236690/139511/?t=%20" frameborder="0" className="mb-4 col-md-5" height="190"></iframe>
				</Row>
			</div>
		);
	}
}

export default Content;
