const contentData = [
  {
    title: "Discord",
    link: "#",
    description:
	  "it's not whether you win or lose, it's how you trash talk your opponent"
  },
  {
    title: "Season 4 Signup",
    link: "#",
    description:
      "because filling in an excel sheet 4 times a year got too difficult for james"
  },
  {
    title: "Previous Seasons",
    link: "#",
    description:
      "Nothing brings together nine people like immortalizing who came tenth"
  },
  {
    title: "Rules",
    link: "http://www.bloodbowl-game.com/files/RulebookBB2.pdf",
    description:
      "the first rule of bloodbowl club..."
  }
];

export default contentData;
